import $ from 'jquery'
import app from '../../scripts/init/react/Bootstrapper'
import { sIsMobileLandscape } from '../../scripts/redux/modules/browser'

$(document).ready(function() {
  const isMobile = sIsMobileLandscape(app.getStore().getState())
  if (isMobile) {
    dhsv_vc_tabs_container_mobile()
    dhsv_vc_tabs_container_mobile_get_p_tags()
  } else {
    dhsv_vc_tabs_container_scroll_top()
  }
  dhsv_vc_tabs_container()
})

function dhsv_vc_tabs_container_mobile() {
  //alert('is Mobile')

  //append content to li
  $('.dhsv_vc_tabs_container .nav-link').each(function() {
    //get nav link id
    $(this).removeClass('active')
    let li_id = $(this).attr('id')
    //get tab id
    let tb_id = $('#tab-item-' + li_id)
    //append content to li
    $(tb_id).insertAfter('#' + li_id)
    //create buttons
    $(
      '<div class="button_wrapper"><button class="nav_link_buttom">Mehr lesen</button></div>'
    ).insertAfter('#' + li_id)
  })

  //set active class / show current content / hide other content
  $('button.nav_link_buttom').click(function(e) {
    e.preventDefault()

    $(this).toggleClass('toggle')
    if ($(this).hasClass('toggle')) {
      $(this).text('schließen')
    } else {
      $(this).text('Mehr lesen')
    }

    $(this)
      .parent()
      .prev()
      .addClass('active')
    //hide content
    $('.dhsv_vc_tabs_container')
      .find('.fade')
      .css({ display: 'none', height: '0', opacity: '0' })
    //show content
    $(this)
      .parent()
      .next()
      .css({ display: 'block', opacity: '1', height: '0' })
    //animatrion
    if (
      $(this)
        .parent()
        .next()
        .hasClass('open')
    ) {
      $(this)
        .parent()
        .next()
        .animate(
          {
            height: 0,
          },
          500
        )
      $(this)
        .parent()
        .next()
        .removeClass('open')
      $(this)
        .parent()
        .next()
        .css('display', 'none')
    } else {
      $(this)
        .parent()
        .next()
        .animate(
          {
            height: $(this)
              .parent()
              .next()
              .get(0).scrollHeight,
          },
          500,
          function() {
            $(this)
              .parent()
              .next()
              .height('auto')
          }
        )
      $(this)
        .parent()
        .next()
        .addClass('open')
    }
  })
}

//get p tags and append them outside thier a tag
function dhsv_vc_tabs_container_mobile_get_p_tags() {
  let p = null
  let li_id = null

  $('.dhsv_vc_tabs_container .nav-link').each(function() {
    //get nav link id
    li_id = $(this).attr('id')
    //find p tag in current element
    p = $('#' + li_id).find('p')
    //append p tag to current element
    $(p).insertAfter('#' + li_id)
    //wrap p tags into container
    $(p).wrapAll('<div class="text_wrapper"></div>')
  })
}

function dhsv_vc_tabs_container() {
  $('.dhsv_vc_tabs_container').each(function() {
    let allListElements = $('li')
    //let test = $(allListElements.id)
    $(this)
      .find(allListElements)
      .appendTo('.dhsv_vc_tabs_container ul.nav')
    $('p').each(function() {
      var $this = $(this)
      if ($this.html().replace(/\s|/g, '').length == 0) $this.remove()
    })
  })
}

function dhsv_vc_tabs_container_scroll_top() {
  $('.dhsv_vc_tabs_container .nav-link').click(function() {
    $('html, body').animate(
      { scrollTop: $('.dhsv_vc_tabs_container').offset().top - 200 },
      1000
    )
  })
}
