/* Don't forget to add string 'news' to function combineReducers in './news.js' */
import { WP_API_BASE } from '../../shared/constants'

const initialState = {
  isLoading: false,
  isLoaded: false,
  error: null,
  posts: {},
}

const LOAD_PRESSE_DATA = 'LOAD_PRESSE_DATA'
const LOAD_PRESSE_DATA_SUCCESS = 'LOAD_PRESSE_DATA_SUCCESS'
const LOAD_PRESSE_DATA_FAILURE = 'LOAD_PRESSE_DATA_FAILURE'

export default (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case LOAD_PRESSE_DATA:
      return {
        ...state,
        isLoading: true,
      }

    case LOAD_PRESSE_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        posts: { ...state.posts, ...payload },
      }

    case LOAD_PRESSE_DATA_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: true,
      }

    default:
      return state
  }
}

export const loadPosts = (offset, per_page, year, id) => (
  dispatch,
  getState
) => {
  dispatch({ type: LOAD_PRESSE_DATA })

  let currentPresse = getState().presse.posts[id]
  if (currentPresse === undefined) {
    currentPresse = new Array()
  }

  return fetch(
    `${WP_API_BASE}presse?offset=${offset}&per_page=${per_page}&year=${year}`,
    {
      method: 'GET',
    }
  )
    .then(response => {
      if (response.ok && response.status === 200) {
        return response.json()
      }
      throw new Error(response.statusText)
    })
    .then(posts => {
      let payload = {}

      if (year === 0) {
        payload[id] = posts
      } else {
        currentPresse[year] = currentPresse[year].concat(posts)
        payload[id] = currentPresse
      }

      dispatch({
        type: LOAD_PRESSE_DATA_SUCCESS,
        payload,
      })
      return posts
    })
    .catch(error => {
      if (process.env.NODE_ENV === 'development') {
        console.error(error) //eslint-disable-line
      }
      dispatch({
        error: error,
        type: LOAD_PRESSE_DATA_FAILURE,
      })
    })
}
