/* Don't forget to add string 'shops' to function combineReducers in './reducer.js' */
import { WP_API_BASE } from '../../shared/constants'

const initialState = {
  isLoading: false,
  isLoaded: false,
  error: null,
  data: {
    jobs: [],
    filters: {},
  },
  filteredData: [],
  page: 0,
}

const LOAD_JOBSFINDER_DATA = 'LOAD_JOBSFINDER_DATA'
const LOAD_JOBSFINDER_DATA_SUCCESS = 'LOAD_JOBSFINDER_DATA_SUCCESS'
const LOAD_JOBSFINDER_DATA_FAILURE = 'LOAD_JOBSFINDER_DATA_FAILURE'
const GET_JOBSFINDER_ITEMS = 'GET_JOBSFINDER_ITEMS'
const SET_JOBSFINDER_PAGE = 'SET_JOBSFINDER_PAGE'

export default (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case LOAD_JOBSFINDER_DATA:
      return {
        ...state,
        isLoading: true,
      }

    case LOAD_JOBSFINDER_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        data: { ...state.data, ...payload },
        filteredData: [...payload.jobs],
      }

    case LOAD_JOBSFINDER_DATA_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: true,
      }

    case GET_JOBSFINDER_ITEMS:
      return {
        ...state,
        filteredData: payload,
        page: 0,
      }

    case SET_JOBSFINDER_PAGE:
      return {
        ...state,
        page: payload,
      }

    default:
      return state
  }
}

export const loadData = () => (dispatch, getState) => {
  dispatch({ type: LOAD_JOBSFINDER_DATA })
  if (getState().jobs.isLoaded) return

  return fetch(`${WP_API_BASE}jobs`, {
    method: 'GET',
  })
    .then(response => {
      if (response.ok && response.status === 200) {
        return response.json()
      }
      throw new Error(response.statusText)
    })
    .then(payload => {
      dispatch({
        payload,
        type: LOAD_JOBSFINDER_DATA_SUCCESS,
      })
      return payload
    })
    .catch(error => {
      if (process.env.NODE_ENV === 'development') {
        console.error(error) //eslint-disable-line
      }
      dispatch({
        error: error,
        type: LOAD_JOBSFINDER_DATA_FAILURE,
      })
    })
}

export const setJobsFilteredData = filters => (dispatch, getState) => {
  let jobs = [...getState().jobs.data.jobs]

  //console.log(filters) // eslint-disable-line

  if (filters.date) {
    const convertDatetoUTC = date => {
      return new Date(
        date.getUTCFullYear(),
        date.getUTCMonth(),
        date.getUTCDate(),
        date.getUTCHours(),
        date.getUTCMinutes(),
        date.getUTCSeconds()
      )
    }

    const getUTCDate = time => {
      const convertedTime = time.split('-')
      const date = new Date()
      date.setHours(0, 0, 0)
      date.setDate(convertedTime[0])
      date.setMonth(convertedTime[1])
      date.setFullYear(convertedTime[2])
      return convertDatetoUTC(date)
    }

    jobs = jobs.filter(item => {
      const selectedUTCDate = getUTCDate(filters.date)
      const jobUTCDate = getUTCDate(item.date)

      return selectedUTCDate <= jobUTCDate
    })
  }

  if (filters.title) {
    jobs = jobs.filter(item =>
      item.title.toUpperCase().includes(filters.title.toUpperCase())
    )
  }

  if (filters.address) {
    jobs = jobs.filter(item =>
      item.address.toUpperCase().includes(filters.address.toUpperCase())
    )
  }

  if (filters.fuehrungsfunktion === '1') {
    jobs = jobs.filter(item => item.fuehrungsfunktion === true)
  } else if (filters.fuehrungsfunktion === '0') {
    jobs = jobs.filter(item => item.fuehrungsfunktion === false)
  }

  const filterByCategory = categoryName => {
    jobs = jobs.filter(item => {
      return item[categoryName].includes(parseInt(filters[categoryName]))
    })
  }

  if ('bverhaeltnis' in filters && filters.bverhaeltnis.length) {
    filterByCategory('bverhaeltnis')
  }

  if ('funktionsgruppe' in filters && filters.funktionsgruppe.length) {
    filterByCategory('funktionsgruppe')
  }

  if ('geschaeftsbereiche' in filters && filters.geschaeftsbereiche.length) {
    filterByCategory('geschaeftsbereiche')
  }

  return dispatch({
    type: GET_JOBSFINDER_ITEMS,
    payload: jobs,
  })
}

export const changePage = page => dispatch => {
  return dispatch({
    type: SET_JOBSFINDER_PAGE,
    payload: page,
  })
}
