/* Don't forget to add string 'shops' to function combineReducers in './reducer.js' */
import { WP_API_BASE } from '../../shared/constants'

const initialState = {
  isLoading: false,
  isLoaded: false,
  error: null,
  data: {
    shops: [],
    categories: [],
  },
  filteredData: [],
}

const LOAD_FILIALENFINDER_DATA = 'LOAD_FILIALENFINDER_DATA'
const LOAD_FILIALENFINDER_DATA_SUCCESS = 'LOAD_FILIALENFINDER_DATA_SUCCESS'
const LOAD_FILIALENFINDER_DATA_FAILURE = 'LOAD_FILIALENFINDER_DATA_FAILURE'
const GET_FILIALENFINDER_ITEMS = 'GET_FILIALENFINDER_ITEMS'

export default (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case LOAD_FILIALENFINDER_DATA:
      return {
        ...state,
        isLoading: true,
      }

    case LOAD_FILIALENFINDER_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        data: { ...state.data, ...payload },
        filteredData: [...payload.shops],
      }

    case LOAD_FILIALENFINDER_DATA_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: true,
      }

    case GET_FILIALENFINDER_ITEMS:
      return {
        ...state,
        filteredData: payload,
      }

    default:
      return state
  }
}

export const loadData = () => (dispatch, getState) => {
  dispatch({ type: LOAD_FILIALENFINDER_DATA })
  if (getState().shops.isLoaded) return

  return fetch(`${WP_API_BASE}shops`, {
    method: 'GET',
  })
    .then(response => {
      if (response.ok && response.status === 200) {
        return response.json()
      }
      throw new Error(response.statusText)
    })
    .then(payload => {
      dispatch({
        payload,
        type: LOAD_FILIALENFINDER_DATA_SUCCESS,
      })
      return payload
    })
    .catch(error => {
      if (process.env.NODE_ENV === 'development') {
        console.error(error) //eslint-disable-line
      }
      dispatch({
        error: error,
        type: LOAD_FILIALENFINDER_DATA_FAILURE,
      })
    })
}

export const setShopsFilteredData = filters => (dispatch, getState) => {
  let locations = [...getState().shops.data.shops]

  if (filters.address) {
    locations = locations.filter(item =>
      item.location.address
        .toUpperCase()
        .includes(filters.address.toUpperCase())
    )
  }

  if (filters.categories.length) {
    filters.categories.map(category => {
      locations = locations.filter(item => {
        return item.categories.includes(category)
      })
    })
  }

  if (filters.work_on_sunday) {
    locations = locations.filter(item => item.working_time.sunday)
  }

  if (filters.working_now) {
    const convertDatetoUTC = date => {
      return new Date(
        date.getUTCFullYear(),
        date.getUTCMonth(),
        date.getUTCDate(),
        date.getUTCHours(),
        date.getUTCMinutes(),
        date.getUTCSeconds()
      )
    }

    const getLocationUTCTime = time => {
      const locationTime = time.split(':')
      const date = new Date()
      date.setHours(locationTime[0], locationTime[1], locationTime[2])
      return convertDatetoUTC(date)
    }

    const days = [
      'sunday',
      'monday',
      'tuesday',
      'wednesday',
      'thursday',
      'friday',
      'saturday',
    ]

    locations = locations.filter(item => {
      const nowUTCDate = convertDatetoUTC(new Date())
      const day = days[nowUTCDate.getDay()]
      const timeFrom = getLocationUTCTime(item.working_time[day + '_from'])
      const timeTo = getLocationUTCTime(item.working_time[day + '_to'])
      return (
        item.working_time[day] && nowUTCDate > timeFrom && nowUTCDate < timeTo
      )
    })
  }

  return dispatch({
    type: GET_FILIALENFINDER_ITEMS,
    payload: locations,
  })
}
