import $ from 'jquery'

$(document).ready(function() {
  dhsv_vc_info_box()
})

function dhsv_vc_info_box() {
  $('.dhsv_vc_info_box').each(function() {
    $('.js-info-box__icon', $(this)).on('click', function() {
      $(this)
        .siblings('.info-box__form-wrapper')
        .slideToggle()
    })

    if (
      $(this)
        .closest('.vc_row')
        .find('.dhsv_vc_jobs_finder_filter_box').length
    ) {
      $(this).addClass('sibling-for-jobs-filters')
    }
  })
}
