import $ from 'jquery'

$(document).ready(function() {
  dhsv_vc_our_promise()
})

$(window).on('resize', function() {
  dhsv_vc_our_promise()
})

function dhsv_vc_our_promise() {
  $('.dhsv_vc_our_promise').each(function() {
    equalHeight()
  })
}

function equalHeight() {
  if ($(window).width() > 991) {
    $('.js-row').each(function() {
      var highestBox = 0
      $('.js-item', this).each(function() {
        if ($(this).height() > highestBox) {
          highestBox = $(this).height()
        }
      })
      $('.js-item', this).height(highestBox)
    })
  } else {
    $('.js-item').css('height', 'auto')
  }
}
