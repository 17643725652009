// import external dependencies
import 'jquery'

// import everything from autoload
import './autoload/_bootstrap.js'; import './autoload/_emptySpace.js'; import './autoload/_object-fit.js' // eslint-disable-line

// import vc-elements javascript files
import '../vc_elements/_new/main.js'; import '../vc_elements/application_buttons/main.js'; import '../vc_elements/button/main.js'; import '../vc_elements/events_grid/main.js'; import '../vc_elements/form_box/main.js'; import '../vc_elements/hero_slider/main.js'; import '../vc_elements/highlights/main.js'; import '../vc_elements/info_box/main.js'; import '../vc_elements/instagramscraper/main.js'; import '../vc_elements/our_promise/main.js'; import '../vc_elements/page_teaser/main.js'; import '../vc_elements/product_assortment/main.js'; import '../vc_elements/products_grid/main.js'; import '../vc_elements/recent_news/main.js'; import '../vc_elements/tabs_container/main.js'; import '../vc_elements/template_element/main.js'; import '../vc_elements/trainees/main.js' // eslint-disable-line

// import react habitat and react components
import './init/react'

// import local dependencies
import Router from './util/Router'
import common from './routes/common'
import home from './routes/home'
import aboutUs from './routes/about'

/** Populate Router instance with DOM routes */
const routes = new Router({
  // All pages
  common,
  // Home page
  home,
  // About Us page, note the change from about-us to aboutUs.
  aboutUs,
})

// Load Events
jQuery(document).ready(() => routes.loadEvents())
