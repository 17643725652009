import { combineReducers } from 'redux'
import browser from './browser'
import router from './router'
import shops from './shops'
import jobs from './jobs'
import presse from './presse'

const appReducer = combineReducers({
  browser,
  location: router,
  shops,
  jobs,
  presse,
})

export default appReducer
