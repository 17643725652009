import $ from 'jquery'
import 'masonry-desandro'

$(document).ready(dhsv_vc_instagramscraper())

function dhsv_vc_instagramscraper() {
  $('.dhsv_vc_instagramscraper').each(function() {
    // init Masonry
    $('.grid').masonry()

    $('[id*="collapse"]')
      .on('show.bs.collapse', function() {
        $(this)
          .prev('.first-part')
          .addClass('active')
        setTimeout(() => {
          $('.grid').masonry()
        }, 50)
      })
      .on('hidden.bs.collapse', function() {
        $(this)
          .prev('.first-part')
          .removeClass('active')
        $('.grid').masonry()
      })
  })
}
