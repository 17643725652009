import $ from 'jquery'

$(document).ready(dhsv_vc_hero_slider())

function dhsv_vc_hero_slider() {
  $('.js-scroll-down').on('click', function() {
    const scrollBtn = $(this)
    $('html, body').animate(
      {
        scrollTop: scrollBtn.offset().top + scrollBtn.outerHeight(),
      },
      600
    )
  })
}
