function smooth_scroll(link, e) {
  if (
    link &&
    link.indexOf('#') > 0 &&
    link.split('#')[0].indexOf(location.origin + location.pathname) !== 0
  ) {
    return false
  }
  if (e) e.preventDefault()
  var hash = false
  if (!link && window.location.hash) {
    hash = window.location.hash
  } else if (link && link.indexOf('#') >= 0) {
    hash = '#' + link.split('#')[1]
  } else if (
    link &&
    location.pathname.replace(/^\//, '') == link.pathname.replace(/^\//, '') &&
    location.hostname == link.hostname
  ) {
    hash = link.hash
  }
  if (hash) {
    $('html, body').animate(
      {
        scrollTop: $(hash).offset().top - $('.header').outerHeight(),
      },
      600
    )
    history.replaceState(null, null, hash)
  }
  $(window).trigger('hashchange')
}

function resizeFullWidthRow() {
  $('[data-vc-full-width="true"]').each(function() {
    $(this).css({
      width: document.body.clientWidth,
      marginLeft: -document.body.clientWidth / 2,
    })
    if (!$(this).attr('data-vc-stretch-content')) {
      // let padding =
      //   (document.body.clientWidth - $('.container.wrap').outerWidth()) / 2
      // $(this).css({
      //   'padding-left': padding,
      //   'padding-right': padding,
      // })
    }
  })
}

const eventsSlideToggle = () => {
  $('.js-more-events').on('click', function() {
    $(this)
      .toggleClass('active')
      .closest('.js-events-wrapper')
      .find('.js-events')
      .slideToggle()
  })
}

const shareButtons = () => {
  $(document).on('click', '.js-share-button', function() {
    $(this)
      .siblings('.share-links')
      .find('.share-links__item')
      .each(function(index, el) {
        setTimeout(function() {
          $(el)
            .stop(true, true)
            .fadeToggle(500)
        }, 40 * index)
      })
  })
}
function throttled(delay, fn) {
  let lastCall = 0
  return function(...args) {
    const now = new Date().getTime()
    if (now - lastCall < delay) {
      return
    }
    lastCall = now
    return fn(...args)
  }
}

const stickyBanner = () => {
  var lastScrollTop = 0
  var scrollTop = undefined
  var body = $('body')
  var distance = $('.banner').offset().top

  function handleScroll() {
    scrollTop = $(window).scrollTop()

    if (scrollTop <= distance) {
      body.removeClass('sticky-nav')
    } else {
      body.addClass('sticky-nav')
    }

    lastScrollTop
  }

  handleScroll()
  $(window).on('scroll', throttled(10, handleScroll))
}

const desktopMenuInit = () => {
  $('.js-header-menu > .dropdown').hover(
    function() {
      const megaMenuDropdown = $(this)

      megaMenuDropdown
        .toggleClass('opened')
        .children('.dropdown-menu')
        .stop(true, true)
        .fadeIn(300, 'linear')
        .closest('.banner')
        .addClass('menu-opened')
    },
    function() {
      $(this)
        .toggleClass('opened')
        .children('.dropdown-menu')
        .stop(true, true)
        .fadeOut(300, 'linear')
        .closest('.banner')
        .removeClass('menu-opened')
    }
  )

  $('.js-header-menu .level-0 .dropdown').hover(
    function() {
      const megaMenuDropdown = $(this)

      megaMenuDropdown
        .toggleClass('opened')
        .children('.dropdown-menu')
        .stop(true, true)
        .fadeIn(300, 'linear')
        .end()
        .closest('.dropdown-menu')
        .addClass('opened')
    },
    function() {
      $(this)
        .toggleClass('opened')
        .children('.dropdown-menu')
        .stop(true, true)
        .fadeOut(300, 'linear')
        .end()
        .closest('.dropdown-menu')
        .removeClass('opened')
    }
  )
}

const mobileMenuInit = () => {
  $('.js-main-navigation-toggler').on('click', function() {
    $(this).toggleClass('active')
    $('#mobile-navigation').slideToggle(300)
    $('.banner').toggleClass('menu-opened')
    $('html').toggleClass('menu-opened')
  })

  $('.dropdown > .nav-link').each(function() {
    const item = $(this)
    const itemTitle = item.html()
    item
      .siblings('.mobile-dropdown')
      .children('.js-submenu-close')
      .html(itemTitle)
  })

  $('#mobile-navigation').on('click', '.dropdown > .nav-link', function(e) {
    if (
      $(this)
        .find('.js-submenu-open')
        .is(e.target)
    ) {
      e.preventDefault()
      $(this)
        .siblings('.mobile-dropdown')
        .fadeIn()
        .parents('.mobile-dropdown')
        .scrollTop(0)
        .addClass('fixed')
    }
  })

  $('#mobile-navigation').on('click', '.js-submenu-close', function() {
    const targetDropdown = $(this).closest('.mobile-dropdown')

    targetDropdown.closest('.mobile-dropdown.fixed').removeClass('fixed')

    targetDropdown.fadeOut()
  })

  const viewport = window.bootstrapToolkit
  const navToggler = $('.js-main-navigation-toggler')
  $(window).resize(
    viewport.changed(function() {
      if (viewport.is('>md') && navToggler.hasClass('active')) {
        navToggler.trigger('click')
      }
    })
  )
}

function doLoginAnimation() {
  $(document).on('focusin', '.login-modal__body .input', function() {
    $(this)
      .parent()
      .addClass('input--focus')
  })
  $(document).on('focusout', '.login-modal__body .input', function() {
    if ($(this).val().length == 0) {
      $(this)
        .parent()
        .removeClass('input--focus')
    }
  })
}

export default {
  init() {
    stickyBanner()
    desktopMenuInit()
    mobileMenuInit()
    // JavaScript to be fired on all pages
    resizeFullWidthRow()
    doLoginAnimation()

    $(window).on('resize', function() {
      resizeFullWidthRow()
    })

    $(window).load(function() {
      resizeFullWidthRow()
    })

    eventsSlideToggle()
    shareButtons()

    /* eslint-disable */
    $(
      'a[href^="#"]:not([href="#"],[href^="#fancy-"],[role="tab"],[data-vc-accordion])'
    ).on('click', function(e) {
      smooth_scroll($(this).attr('href'), e)
    })
    /* eslint-enable */
    if (window.location.hash) {
      smooth_scroll(window.location.hash)
    }
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
    // $('.js-dropdown-toggle').on('click', function(e) {
    //   e.preventDefault()
    //   $(this)
    //     .next('.dropdown-menu')
    //     .slideToggle()
    // })
  },
}
