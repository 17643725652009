import $ from 'jquery'

$(document).ready(function() {
  dhsv_vc_form_box()

  $(document).on('gform_post_render', function() {
    dhsv_vc_form_box()
  })
})

function dhsv_vc_form_box() {
  $('.dhsv_vc_form_box').each(function() {
    $('.ginput_container_textarea')
      .closest('.gfield')
      .find('.gfield_label')
      .addClass('js-show')
  })
}
